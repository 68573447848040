.bubble {
    --r: 30px;
    --t: 2.5em;
    width: 540px;
    // max-width: 300px;
    padding: 20px;
    border-inline: var(--t) solid #0000;
    border-radius: calc(var(--r) + var(--t))/var(--r);
    mask:
        radial-gradient(100% 100% at var(--_p) 100%, #0000 99%, #000 102%) var(--_p) 0/var(--t) var(--t) no-repeat,
        linear-gradient(#000 0 0) padding-box;
    position: absolute;
}

.left {
    --_p: 0;
    border-top-left-radius: 0 0;
    place-self: start;
}

.right {
    --_p: 100%;
    border-top-right-radius: 0 0;
    place-self: end;
}