.hangingBoard {
  position: relative;
  width: 100px;
  height: 30px;
  animation: swing 2s infinite alternate;
  transform-origin: top;
  padding-top: 40px;
}

.board {
  position: relative;
  width: 100px;
  height: 30px;
  background-color: var(--themeColor);
  border-radius: 10px;
  // box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.rope {
  position: absolute;
  top: -40px;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: var(--themeColor);
}

.ropeLeftHang {
  position: absolute;
  top: -15px;
  left: 42%;
  width: 1px;
  height: 65%;
  background-color: var(--themeColor);
  transform: rotate(60deg);
}

.ropeRightHang {
  position: absolute;
  top: -15px;
  left: 58%;
  width: 1px;
  height: 65%;
  background-color: var(--themeColor);
  transform: rotate(-60deg);
}

.text {
  position: absolute;
  top: 115%;
  left: 7%;
  font-size: 13px;
  font-weight: 600;
  color: white;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}