.border-container {
    --border-angle: 0turn;
    /* For animation */
    animation: bg-spin 3s linear infinite;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    top: 5%;
    left: 5%;
    border: 1px solid #FF5D22;
    z-index: -1;
}

.border-theme-core {
    --main-bg: conic-gradient(from var(--border-angle),
            #fff,
            #fff 5%,
            #fff 60%,
            #fff 95%);
    border: solid 2px transparent;
    border-radius: 30px;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #FF5D22, #FF5D22 99%, transparent);
    // linear-gradient(to_left,#FF5D2233_40%,rgb(255,255,255,0)_100%)
    background:
        var(--main-bg) padding-box,
        var(--gradient-border) border-box,
        var(--main-bg) border-box;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

// .border-main{
//     --color: #FF5D22; /* the border color */
//     --border: 2px;   /* the border thickness*/
//     --offset: 15px;   /* control the offset*/
//     --gap: 15px;       /* the gap on hover */
    
//     --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)),var(--color) 0;
//     --_o: calc(3*var(--offset));
//     padding: 
//       calc(var(--gap) + var(--border))
//       calc(var(--gap) + var(--border) + var(--offset))
//       calc(var(--gap) + var(--border) + var(--offset))
//       calc(var(--gap) + var(--border));
//     background: 
//       linear-gradient(      var(--_c)) var(--_o) var(--_o), 
//       linear-gradient(90deg,var(--_c)) var(--_o) var(--_o);
//     background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
//     background-repeat: no-repeat;
//     filter: grayscale(.4);
//     transition: .5s;
//     cursor: pointer;
//     border-radius: 30px;
// }
// .border-main:hover {
//     background-position: 0px 0px;
//     background-size: calc(100% - var(--offset)) calc(100% - var(--offset));
//     filter: grayscale(0);
//   }