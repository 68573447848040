.themed-border {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #FF5D22, rgba(255, 93, 34, 0)) border-box;
  border: 1px solid transparent;
}

.theme-tabs .ant-tabs-ink-bar {
  background: var(--themeColor);
}

.theme-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #292929;
  font-size: 22px;
  font-weight: 700;

}

.theme-tabs .ant-tabs-tab:hover {
  color: var(--themeColor);
}

.theme-tabs .ant-tabs-tab {
  font-size: 22px;
  font-weight: 500;
}

.theme-tabs .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.theme-tabs .ant-tabs-nav {
  margin-bottom: 0px !important;
}

.theme-tabs {
  box-shadow: 0px 14px 54px 0px #0000001A;
  border-radius: 80px;
  padding: 0px 20px;
}

.theme-tabs .ant-tabs-nav-wrap {
  display: block !important;
}

.theme-tabs .ant-tabs-nav-list {
  justify-content: space-around;
}

.collapse-style .ant-collapse-item {
  border-bottom: 1px solid #FFCBC6 !important;
}

.collapse-style .ant-collapse-item[aria-expanded="true"] {
  padding-bottom: 0px;
}

.collapse-style .ant-collapse-header-text {
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
}

.collapse-style .ant-collapse-content-box {
  padding-left: 0px !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #FF94067A;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f38c0590;
}

.dropdown-arrow .ant-select {
  margin-right: -25px !important;
}

.dropdown-arrow .ant-select-selector {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 3px !important;
}

.dropdown-container {
  width: max-content !important;
}

.dropdown-container .ant-select-item-option-selected {
  background: none !important;
  color: var(--themeColor) !important;
}

.dropdown-container .ant-select-item-option-selected .ant-select-item-option-content span {
  font-weight: 600 !important;
}

.faq-collapse-style .ant-collapse-item {
  border-bottom: 1px solid #BEBEBE !important;
}

.faq-collapse-style .ant-collapse-item[aria-expanded="true"] {
  padding-bottom: 0px;
}

.faq-collapse-style .ant-collapse-header-text {
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
}

.faq-collapse-style .ant-collapse-item-active {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0) 93.75%) !important;
  border-bottom: none !important;
  box-shadow: 0px 14px 114px 0px #0000001A;
}

.sub-title {
  font-size: 35px;
  line-height: 44px;
  font-weight: 500;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .sub-title {
    font-size: 30px;
    line-height: 44px;
  }

  .theme-tabs .ant-tabs-tab {
    font-size: 12px;
  }

  .theme-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 12px;
  }

  .faqs :where(.css-dev-only-do-not-override-grj8l9).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
    font-size: 18px;
  }

  .faqs :where(.css-dev-only-do-not-override-grj8l9).ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 30px 16px 10px 20px;
  }
}

@media (min-width: 650px) and (max-width: 1024px) {}

@media (min-width: 1024px) {}