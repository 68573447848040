.button {
  padding: 12px;
  border-radius: 15px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  background: var(--themeColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 5px 24px 0px #FF5D2254;
}
.button:disabled {
  // background-color: #ced4da !important;
  // border: 1px solid #ced4da;
  opacity: 0.6;
  cursor: not-allowed;
}

// .button:enabled {
//   background: linear-gradient(146.18deg,#FF9406 14.38%,#FF5E4D 86.44%);
// }

.button:focus-within{
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}
.button:hover{
  background: linear-gradient(146.18deg,#ff905c 14.38%,#fd0d0d 86.44%);
}

.button:focus {
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}

.loaderStyle{
  height: 100%;
}