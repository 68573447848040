.playPauseButton{
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: transparent;
    backdrop-filter: blur(4px);
    // background: white;
    top: 45%;
    // left: 40%;
    filter: contrast(0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullScreenButton{
    position: absolute;
    display: none !important;
    right: 15%;
    top: 10%;
}

.videoContainer:hover{
    .playPauseButton{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fullScreenButton{
        display: block !important;
    }
}