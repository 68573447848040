.greyTextArea {
    resize: none;
    padding: 17px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: #D5D5D566;
}

.greyInput {
    padding: 17px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: #D5D5D566;
}

.greyInput[type="text"],
.greyInput[type="number"],
.greyInput[type="email"],
.greyTextArea {
    color: black !important;
    font-size: 20px;
    font-weight: 500;

    &::placeholder {
        color: #4F4F4F !important;
        transition: 1s;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }
}

.greyInput::-webkit-outer-spin-button,
.greyInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.greyInput[type="number"] {
    -moz-appearance: textfield;
}

.greyInput[type="text"]:focus,
.greyInput[type="number"]:focus,
.greyInput[type="email"]:focus,
.greyTextArea:focus {
    border: 1px solid transparent;
    background: #FFFFFF;
    box-shadow: 0px 6px 34px 0px #3030303B;
    outline: none !important;
}

.greyInput[type="number"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

.timepicker {
    background: #D5D5D566;
    // border: 1px solid #c0cece;
    border-radius: 12px;
    outline: none;
    transition: 0.5s;
    color: #000;
    padding: 17px;

    &:hover {
        border: 1px solid #FFFFFF;
        background: #D5D5D566;
        transition: 0.5s;
        color: #000 !important;
        border-image-slice: 1;
    }

    &::-webkit-scrollbar {
        display: none !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        display: none !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
}

.ant-picker-now-btn {
    display: none;
}

.ant-picker-ok {
    cursor: pointer;
}

.ant-btn-sm {
    background: #4F4F4F;
    border: none;
    width: 35px;
    height: 24px;
    background: #01a8ac;
    border: 1px solid #01a8ac;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(95.39deg,
                #b2d236 9.4%,
                #2bb24b 51.68%,
                #01a8ac 96.92%);
        border: none;
    }

    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        font-family: "Poppins";
    }
}

.ant-picker-time-panel-cell-inner {
    color: #01a8ac;
}

.ant-picker-panel-container {
    margin-top: -3px;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    isolation: isolate;
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
}

.ant-picker-input {
    font-family: "Century Gothic";
    margin-left: 6px;
    color: #026669;

    &::placeholder {
        color: #c0cece;
    }
}

.ant-picker-suffix {
    display: none;
}

.ant-picker-clear {
    display: none;
}

.ant-picker-focused {
    border-bottom: 1px solid transparent;
    box-shadow: none;
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
    display: block;
    border-radius: 16px 16px 0px 0px;
}

.ant-picker {
    display: block;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: #026669;

    &:hover {
        background: rgba(187, 240, 241, 30%);
        cursor: pointer;
    }
}

.ant-picker-panel {
    .ant-picker-time-panel {
        color: #026669;
    }
}

.ant-picker-dropdown {
    // width: 117px;
    top: 1407px;
}

.ant-picker-time-panel-column {
    width: 41px;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        width: 38.2px;
    }

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        width: 56px;
    }
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: none;
}

.ant-picker-input>input {
    color: #026669;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-picker .ant-picker-suffix{
    display: none;
}