.themeTextArea {
    resize: none;
    padding: 17px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: rgba(253, 236, 232);
}

.themeInput {
    padding: 17px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: rgba(253, 236, 232);

    @media screen and (min-width: 300px) and (max-width: 768px) {
        padding: 10px;
    }
}

.themeInput[type="text"],
.themeInput[type="number"],
.themeInput[type="email"],
.themeTextArea {
    color: #FF5D22 !important;
    font-size: 20px;
    font-weight: 500;

    &::placeholder {
        color: #4F4F4F !important;
        transition: 1s;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }
}

.themeInput::-webkit-outer-spin-button,
.themeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.themeInput[type="number"] {
    -moz-appearance: textfield;
}

.themeInput[type="text"]:focus,
.themeInput[type="number"]:focus,
.themeInput[type="email"]:focus,
.themeTextArea:focus {
    border: 1px solid transparent;
    background: #FFFFFF;
    box-shadow: 0px 5px 24px 0px #FF5D2254;
    outline: none !important;
}

.themeInput[type="number"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}