.playPauseButton {
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: transparent;
    backdrop-filter: blur(4px);
    // background: white;
    top: 45%;
    left: 40%;
    filter: contrast(0.6);
}

.fullScreenButton {
    position: absolute;
    display: block !important;
    right: 5%;
    top: 4%;
}

.videoContainer:hover {
    .playPauseButton {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .fullScreenButton {
    //     display: block !important;
    // }
}

.videoContainer::after {
    content: "\f106";
    position: absolute;
    left: 0;
    color: #221638;
    top: -20px;
    line-height: 1;
    z-index: -1;
    right: 0;
    opacity: .05;
    font-family: Flaticon;
    font-size: 150px;
}