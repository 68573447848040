.gallery-tabs {
    box-shadow: none !important;
    border: none;
    padding: 0px 0px;

    .ant-tabs-ink-bar {
        background: none;
    }

    .ant-tabs-tab {
        background-color: #EFECEE;
        font-size: 18px;
        width: 229px;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        border: 1px solid #fff;
        color: #4F4F4F;

        @media screen and (min-width: 300px) and (max-width: 600px) {
            width: auto;
            padding: 6px 10px;
            font-size: 12px;
            margin: 0 0 0 8px !important;
        }
    }

    .ant-tabs-tab-active {
        background-color: #FFF;
        font-size: 18px;
        width: 229px;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0px solid transparent !important;
        box-shadow: 0px 5px 24px 0px #FF5D223B;
        font-weight: 400 !important;

        @media screen and (min-width: 300px) and (max-width: 600px) {
            font-size: 12px;
            margin: 0 0 0 8px !important;
            width: auto;
            padding: 6px 10px;

        }

        .ant-tabs-tab-btn {
            color: #FF5D22 !important;
            font-size: 18px !important;
            font-weight: 400;

            @media screen and (min-width: 300px) and (max-width: 600px) {
                font-size: 12px !important;

            }
        }


    }
}