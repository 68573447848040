.greyTextArea {
    resize: none;
    padding: 17px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: #D5D5D566;
}

.greyInput {
    padding: 17px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: #D5D5D566;
}

.greyInput[type="text"],
.greyInput[type="number"],
.greyInput[type="email"],
.greyTextArea {
    color: black !important;
    font-size: 20px;
    font-weight: 500;

    &::placeholder {
        color: #4F4F4F !important;
        transition: 1s;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }
}

.greyInput::-webkit-outer-spin-button,
.greyInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.greyInput[type="number"] {
    -moz-appearance: textfield;
}

.greyInput[type="text"]:focus,
.greyInput[type="number"]:focus,
.greyInput[type="email"]:focus,
.greyTextArea:focus {
    border: 1px solid transparent;
    background: #FFFFFF;
    box-shadow: 0px 6px 34px 0px #3030303B;
    outline: none !important;
}

.greyInput[type="number"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}