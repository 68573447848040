@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

// .marquee-content {
//     display: flex;
//     align-items: center;
//     gap: 40px;
//     transform: skew(5deg);
//     animation: marquee 10s linear infinite;
// }

.skew-box {
    background-color: black;
    height: 128px;
    display: flex;
    align-items: center;
    transform: skewY(-7deg);
    overflow: hidden;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        height: 85px;
    }
}